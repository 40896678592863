export const LINE_URL = 'https://line.me/R/ti/p/@616fszhu';
export const PHONE_NUMBER_CONTACT = '02-483-0933';
export const SUPPLY_CONTACT_EMAIL = 'purchasing.thteam@buymed.com';
export const BRAND_NAME_SUPPORT = '@Buymed';

export default {
  LINE_URL,
  PHONE_NUMBER_CONTACT,
  BRAND_NAME_SUPPORT,
  SUPPLY_CONTACT_EMAIL,
};
