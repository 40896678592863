import { getLinkProxy } from 'utils/ImageUtils';
import { ENV_COUNTRY, NEW_MISSING_IMAGE, PROXY_IMAGE } from '../../sysconfig';
// logo cũ
export const MISSING_IMAGE_CACHED = `${PROXY_IMAGE}/thuocsi-live/web/static/images/missing.png?size=200`;
// apo-1409: logo mới
export const NEW_MISSING_IMAGE_CACHED = getLinkProxy(NEW_MISSING_IMAGE);

export const STORE_IMAGE_DEFAULT_CACHED = `${PROXY_IMAGE}/thuocsi-live/web/static/images/default_store.svg?size=origin`;
export const GIFT_IMAGE_DEFAULT_CACHED = `${PROXY_IMAGE}/thuocsi-live/web/static/images/gift.jpeg?size=origin`;
// logo buymed
// const mapLogoBuyMed = {
//   vn: `${PROXY_IMAGE}/thuocsi-live/web/static/images/logo_medx.png?size=origin`,
// }

// https://storage.googleapis.com/thuocsi-live/web/static/images/logo-buymed-th.png
// export const LOGO_BUYMED_GG = `${PROXY_IMAGE}/thuocsi-live/web/static/images/logo_buymed_th.svg`;
// https://storage.googleapis.com/thuocsi-live/web/static/images/logo-buymed-th.svg
// todo : multi
export const LOGO_BUYMED_GG = `${PROXY_IMAGE}/thuocsi-live/web/static/images/logo-buymed-th-2.svg`;
export const LOGO_MEDX_GG = ENV_COUNTRY === 'vn' ? `${PROXY_IMAGE}/thuocsi-live/web/static/images/logo_medx.png?size=origin` : LOGO_BUYMED_GG;
export const CONDITIONS = `${PROXY_IMAGE}/thuocsi-live/web/static/images/conditions-en.svg`;

export default {
  MISSING_IMAGE_CACHED,
  GIFT_IMAGE_DEFAULT_CACHED,
  STORE_IMAGE_DEFAULT_CACHED,
};
