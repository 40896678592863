import React, { useState, useRef } from 'react';
import styles from './styles.module.css';

function DraggableComponent({ initialPosition, children }) {
  const [position, setPosition] = useState(initialPosition);
  const [dragging, setDragging] = useState(false);
  const dragRef = useRef(null);

  const handleDragStart = (e) => {
    if (!dragRef?.current) {
      return;
    }
    if (e.dataTransfer) {
      e.dataTransfer.setDragImage(new Image(), 0, 0);
    }
    const boundingRect = dragRef.current.getBoundingClientRect();
    const offsetX = e.clientX - boundingRect.left;
    const offsetY = e.clientY - boundingRect.top;
    dragRef.current.offset = { offsetX, offsetY };
    setDragging(true);
  };

  const handleDrag = (e) => {
    if (!dragRef?.current) {
      return;
    }
    const { offsetX, offsetY } = dragRef.current.offset || {};
    const left = e.clientX - offsetX;
    const top = e.clientY - offsetY;
    if (left < 0 || top < 0 || left > window.innerWidth || top > window.innerHeight) {
      setPosition(initialPosition);
    } else {
      setPosition({
        left: e.clientX - offsetX,
        top: e.clientY - offsetY,
      });
    }
  };

  const handleDragEnd = (e) => {
    if (!dragRef?.current) {
      return;
    }
    setDragging(false);
    const { offsetX, offsetY } = dragRef.current.offset || {};
    const left = e.clientX - offsetX;
    const top = e.clientY - offsetY;
    if (left < 0 || top < 0 || left > window.innerWidth || top > window.innerHeight) {
      setPosition(initialPosition);
    } else {
      setPosition({
        left: e.clientX - offsetX,
        top: e.clientY - offsetY,
      });
    }
  };

  const handleTouchStart = (e) => {
    handleDragStart(e.touches[0]);
  };

  const handleTouchMove = (e) => {
    handleDrag(e.touches[0]);
  };

  const handleTouchEnd = (e) => {
    handleDragEnd(e.changedTouches[0]);
  };
  return (
    <div
      draggable
      ref={dragRef}
      className={`${styles.draggable} ${dragging ? styles.dragging : ''}`}
      style={{
        position: 'fixed',
        top: position?.top || 'auto',
        left: position?.left || 'auto',
        bottom: position?.bottom || 'auto',
        right: position?.right || 'auto',
      }}
      onDrag={handleDrag}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {children}
    </div>
  );
}

export default DraggableComponent;
