const LIST_STORAGE_KEY_NOT_CLEAR = ['timeBindingLINE'];
const clearStorage = () => {
  if (window && window.localStorage) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in localStorage) {
      if (!key?.startsWith('ins') && !key?.startsWith('sp') && !LIST_STORAGE_KEY_NOT_CLEAR.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }
};

export default {
  clearStorage,
};
