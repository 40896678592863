import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

export default function ScrollToTop({ pathname }) {
  const divRef = useRef(null);
  const page = pathname.replace(/[/]/g, '');
  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300 && !divRef.current?.classList?.contains('visible')) {
      divRef.current?.classList?.add('visible');
    }
    if (window.pageYOffset <= 300 && divRef.current?.classList?.contains('visible')) {
      divRef.current?.classList?.remove('visible');
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div ref={divRef} className={`back-to-top ${page}`} onClick={scrollToTop} role="presentation" style={{ backgroundColor: '#62C590' }}>
      <div style={{ paddingTop: '6px', color: '#fff' }}>
        <FontAwesomeIcon icon={faChevronUp} style={{ width: '16px', height: '16px' }} />
      </div>
    </div>
  );
}
