import { GET } from './Clients';

async function getAppList({ name }) {
  return GET({ url: `/core/config-manager/v1/app/list?q=${JSON.stringify({ name })}`, isBasic: true });
}

async function getAppValueByCode({ ctx, appCode }) {
  return GET({ url: `/core/config-manager/v1/app-value/single?appCode=${appCode}`, isBasic: true, ctx });
}

export default {
  getAppList,
  getAppValueByCode,
};
