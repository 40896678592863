export const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || null;

const convertProduct = (item, cart) =>
  item
    ? {
        contents: [
          {
            content_name: item?.name,
            content_id: item?.sku,
            quantity: item?.quantity || 1,
            price: item?.displayPrice || item?.price || 0,
          },
        ],
        content_type: 'product',
        currency: item?.currency || 'THB',
        value: cart?.totalPrice || null,
      }
    : {
        contents: cart?.cartItems?.map((p) => ({
          content_name: p?.name,
          content_id: p?.sku,
          quantity: p?.quantity || 1,
        })),
        content_type: 'product',
        value: cart.totalPrice || 0,
        currency: item?.currency || 'THB',
      };

export const eventTiktok = (name, data = {}) => {
  try {
    if (window && window.ttq) window.ttq.track(name, data);
  } catch (error) {
    console.error('Tiktok event error', error);
  }
};

const viewProduct = (product) => {
  try {
    eventTiktok('ViewContent', convertProduct(product));
  } catch (error) {
    console.error('Tiktok viewProduct error', error);
  }
};

const addToCart = (product, cart) => {
  try {
    eventTiktok('AddToCart', convertProduct(product, cart));
  } catch (error) {
    console.error('Tiktok addToCart error', error);
  }
};

const addToWishlist = (product, cart) => {
  try {
    eventTiktok('AddToWishlist', convertProduct(product, cart));
  } catch (error) {
    console.error('Tiktok addToWishlist error', error);
  }
};

// const purchase = ({ content_ids, currency = 'BATH', num_items = 0, value = 0 }) => event('Purchase', { content_ids, currency, num_items, value });
const purchase = ({ totalPrice, totalQuantity, currency = '', orderId, orderID }) => {
  try {
    eventTiktok('PlaceAnOrder', {
      content_type: 'product',
      value: totalPrice,
      quantity: totalQuantity,
      currency: currency || 'THB',
      content_id: orderID || orderId || '',
    });
  } catch (error) {
    console.error('Tiktok purchase error', error);
  }
};

const completePayment = ({ totalPrice, totalQuantity, currency = '', orderId, orderID }) => {
  try {
    eventTiktok('CompletePayment', {
      content_type: 'product',
      value: totalPrice,
      quantity: totalQuantity,
      currency: currency || 'THB',
      content_id: orderID || orderId || '',
    });
  } catch (error) {
    console.error('Tiktok purchase error', error);
  }
};

const initiateCheckout = (cart) => {
  try {
    eventTiktok('InitiateCheckout', {
      content_id: cart.cartId || cart.cartID || '',
      value: cart.totalPrice,
      currency: 'THB',
      quantity: cart.totalQuantity,
    });
  } catch (error) {
    console.error('Tiktok initiateCheckout error', error);
  }
};

// These events are still missing.
// 1. SubmitApplication
// 2. CompleteRegister
// 3. Purchase

const completeRegistration = () => {
  try {
    eventTiktok('CompleteRegistration', {
      content_id: 'register',
    });
  } catch (error) {
    console.error('Tiktok CompleteRegistration error', error);
  }
};

const submitApplication = () => {
  try {
    eventTiktok('SubmitApplication', {
      content_id: 'register',
    });
  } catch (error) {
    console.error('Tiktok SubmitApplication error', error);
  }
};

// SubmitForm

const submitForm = () => {
  try {
    eventTiktok('SubmitForm', {
      content_id: 'register',
    });
  } catch (error) {
    console.error('Tiktok SubmitForm error', error);
  }
};

export default {
  TIKTOK_PIXEL_ID,
  viewProduct,
  addToCart,
  addToWishlist,
  initiateCheckout,
  purchase,
  completePayment,
  completeRegistration,
  submitApplication,
  submitForm,
};
