import { MarketingClient } from 'clients';

export const getSections = async ({ q = {}, ...restProps }) => {
  const result = MarketingClient.getSections({ q, ...restProps });
  return result;
};

export const getTrendSearchs = async ({ isActive, ...props }) => {
  const result = MarketingClient.getTrendSearch({ isActive, ...props });
  return result;
}

export default { getSections, getTrendSearchs };
