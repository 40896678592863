import { CartClientV2, getData, getFirst, isValid, ProductClientV2, WebServiceClient } from 'clients';
import { PAGE_SIZE_30, PreventSearchKeywords, PreventSearchKeywordsAlias } from 'constants/data';
import { HTTP_STATUS } from 'constants/Enums';
import { GIFT_IMAGE } from 'constants/Images';
import { ENV_COUNTRY, SELLER_CODES_REQUIRE_GPP, TAG_NEW } from 'sysconfig';
import { ResponseUtils, StringUtils } from 'utils';
import { convertArrayToMap } from 'utils/ArrUtils';
import { formatCurrency, formatNumber } from 'utils/FormatNumber';
import { getProxyImageList } from 'utils/ImageUtils';
import { capitalizeTextFirst } from 'utils/StringUtils';
import WebServiceUtils from 'utils/WebServiceUtils';

/* ============= CONSTANT ============== */
const DEFAULT_SEARCH_STRATEGY = {
  text: true,
  keyword: true,
  ingredient: true,
};
const mapLimitOrderDay = {
  vn: 'Đặt tối đa {quantity} {unit}/ngày',
  en: 'Buy maximum {quantity} {unit}/day',
  cam: 'កំណត់ {quantity} អតិបរមា {unit}/ថ្ងៃ',
  th: 'กำหนดสูงสุด {quantity} {unit}/วัน',
};
const mapLimitOrderTime = {
  vn: 'Đặt tối đa {quantity} {unit}/lần',
  en: 'Buy maximum {quantity} {unit}/order',
  cam: 'ទិញអតិបរមា {quantity} {unit}/មួយដង',
  th: 'ตั้งค่าสูงสุด {quantity} {unit}/ครั้ง',
};
const mapAndLimit = {
  vn: 'và {quantity} {unit}/lần',
  en: 'and maximum {quantity} {unit}/order',
  cam: 'និងអតិបរមា {quantity} {unit}/មួយដង',
  th: 'และ {quantity} {unit}/ครั้ง',
};

/* ============= PRIVATE FUNCTION ============== */
const convertSlug = (slug) => slug?.toLowerCase() || slug;

const findDiscountPercent = (salePrice, displayPrice) => {
  if (salePrice === displayPrice || displayPrice > salePrice) return 0;
  let percentDiscountProduct = ((salePrice - displayPrice) / salePrice) * 100;
  if (percentDiscountProduct > 100) percentDiscountProduct = 100;
  return Math.floor(percentDiscountProduct);
};

const getLimitOrder = (limitPerday, limitPerOrder, unit) => {
  let messageLimitOrder = '';
  if (limitPerday > 0 && limitPerday < limitPerOrder) {
    // messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày`;
    messageLimitOrder = mapLimitOrderDay[`${ENV_COUNTRY}`]?.replace('{quantity}', formatNumber(limitPerday)).replace('{unit}', unit || 'sản phẩm');
  }
  if (limitPerday <= 0 && limitPerOrder > 0 && limitPerOrder < 100000) {
    // messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerOrder)} ${unit || 'sản phẩm'}/lần`;
    messageLimitOrder = mapLimitOrderTime[`${ENV_COUNTRY}`]?.replace('{quantity}', formatNumber(limitPerOrder)).replace('{unit}', unit || 'sản phẩm');
  }
  if (limitPerOrder <= 0 && limitPerday > 0) {
    // messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày`;
    messageLimitOrder = mapLimitOrderDay[`${ENV_COUNTRY}`]?.replace('{quantity}', formatNumber(limitPerday)).replace('{unit}', unit || 'sản phẩm');
  } else if ((limitPerOrder > 0 && limitPerday > 0 && limitPerday === limitPerOrder) || limitPerday > limitPerOrder) {
    // messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày ${
    //   limitPerday !== limitPerOrder ? `và ${formatNumber(limitPerOrder)} ${unit || 'sản phẩm'}/lần` : ''
    // }`;
    messageLimitOrder = mapLimitOrderDay[`${ENV_COUNTRY}`]?.replace('{quantity}', formatNumber(limitPerday)).replace('{unit}', unit || 'sản phẩm');
    messageLimitOrder +=
      limitPerday !== limitPerOrder
        ? ` ${mapAndLimit[`${ENV_COUNTRY}`]?.replace('{quantity}', formatNumber(limitPerOrder)).replace('{unit}', unit || 'sản phẩm')}`
        : '';
  }
  return messageLimitOrder;
};

const convertProductV2 = (item = {}, isGetFullTags = false) => {
  const {
    isAvailable = true,
    product,
    sku,
    deal = null,
    nextPriceLevel = {},
    campaign = null,
    skuDescription = {},
    limitPerDay = 0,
    quantityPurchasedToday = 0,
    consumedMaxQuantity = null, // web service
    // feature display product without login
    withoutLogin = false,
    isProductCard = false,
    consumedTags = [],
    consumedPriorityTags = [],
  } = item || {};

  const {
    categoryCodes = null,
    name = '',
    origin = '',
    volume = null,
    unit = null,
    manufacturerCode = null,
    ingredients = [],
    ingredientCodes = [], // product detail sẽ trả ra thêm 1 field là ingredientCodes
    productID = '',
    documentFiles = [],
    weight = 0,
    height = 1,
    length = 1,
    width = 1,
    sellerSubCategoryCode = '',
  } = product || {};

  const {
    maxQuantityPerOrder = null,
    productCode = '',
    retailPriceType = '',
    retailPriceValue = 0,
    sellerCode = null,
    status = '',
    code = '',
    tags = [],
    slug = null,
    statusData = null,
    type,
    skus = [],
    point = null,
    pointMultiplier = null,
    isActive, // true la co hien thi tren web, false la bi tat hien thi
    lotDates = [], // thong tin ve can date
    requiredCertificates = [],
    isAvailabilityForCustomerScope = true,
  } = sku || {};

  const { maxQuantity: maxQuantityDeal } = deal || {};

  const tagExpired = lotDates.find((ele) => ele?.isNearExpired === true) || {};

  const { expiredDate = '', isNearExpired = false } = tagExpired;

  // giá hợp đồng
  const isContractPrice = type === 'SKU_CONTRACT';

  const isDeal = deal && deal?.status === 'ACTIVE' && new Date(deal?.startTime) <= new Date() ? true : null;

  const imageUrls = isDeal && deal?.imageUrls?.length > 0 ? deal?.imageUrls : item?.product?.imageUrls || [];
  const descriptionInfo = item?.description || {};
  if (skuDescription && skuDescription?.description) {
    descriptionInfo.description = skuDescription.description;
  }

  // const salePrice = deal?.price || retailPriceValue;
  // ưu tiên campaign

  const isCampaign = !!campaign || false;
  const isHappeningCampaign = (campaign && campaign?.isShow) || false;
  // anpham: push status into tags
  const newTags = (status !== 'NORMAL' && status !== 'NEAR_EXPIRATION' && [...tags, status]) || [...tags];
  if (isNearExpired) newTags.push('NEAR_EXPIRATION');
  // const isNew = tags?.indexOf(TAG_NEW) >= 0;
  const isNew = consumedTags?.indexOf(TAG_NEW) >= 0;
  // logic check sản phẩm này có dc hiển thị hay ko ở đây
  let errorMessageProduct = null;
  let customMessageproduct = null;
  let requireGPPMessage = null;

  if (SELLER_CODES_REQUIRE_GPP?.length > 0 && sellerCode && SELLER_CODES_REQUIRE_GPP.indexOf(sellerCode) >= 0) {
    // requireGPPMessage = 'Yêu cầu cung cấp GPP và giấy phép kinh doanh';
    requireGPPMessage = 'requireGPPMessage';
  }

  // off isAvailable
  // feature display product without login
  if (!withoutLogin) {
    if (!retailPriceValue || retailPriceValue === 0 || status === 'STOP_SELLING') {
      // errorMessageProduct = 'Đang cập nhật giá';
      errorMessageProduct = 'STOP_SELLING';
    }
    if (isAvailable !== null && !isAvailable) {
      // errorMessageProduct = 'Sản phẩm chưa hỗ trợ giao vùng này';
      if (!isAvailabilityForCustomerScope) {
        errorMessageProduct = 'not_support_scope';
      } else {
        errorMessageProduct = 'not_support_delivery';
      }
    }
    if (!isActive) {
      // errorMessageProduct = 'Sản phẩm tạm ngưng bán';
      errorMessageProduct = 'SUSPENDED';
    }
    switch (status) {
      case 'SUSPENDED':
        // errorMessageProduct = 'Sản phẩm đã ngưng bán';
        errorMessageProduct = 'SUSPENDED';
        break;
      case 'STOP_PRODUCING':
        // errorMessageProduct = 'Sản phẩm ngưng sản xuất';
        errorMessageProduct = 'STOP_PRODUCING';
        break;
      case 'OUT_OF_STOCK':
        // errorMessageProduct = 'Sản phẩm tạm hết hàng';
        errorMessageProduct = 'OUT_OF_STOCK';
        break;
      default:
    }
  }
  const dealPrice = (isDeal && deal && deal?.price) ?? null;
  const imagesProxy = getProxyImageList(imageUrls);

  const isGift = status === 'GIFT';

  // variable template
  // update formatedCurrency
  let displayPrice = dealPrice ?? retailPriceValue;

  // product type
  let productType = 'NORMAL';
  let typeItem = 'NORMAL';
  const hidenPrice = null;
  let maxQuantity = maxQuantityPerOrder;
  const maxQuantityPerOrderItem = maxQuantityPerOrder;

  // percentage of quantity sold and sold out condition
  let percentDealSold;
  // available products to be able to buy in campaign
  let availableProducts;
  let discountPercentage;
  const messageLimitOrder = getLimitOrder(limitPerDay, maxQuantityPerOrderItem, unit);

  // nếu có campaign
  if (isHappeningCampaign) {
    const {
      campaign: campaignInfo,
      maxQuantityPerOrder: maxQuantityPerOrderCampaign = maxQuantityPerOrder,
      quantity: maxQuantityCampaign,
      retailPriceValue: retailPriceCampaign,
      soldQuantity = 0,
    } = campaign;

    percentDealSold = (soldQuantity / campaign?.quantity) * 100;

    if (percentDealSold < 100) {
      newTags.push('DEAL');
      discountPercentage = findDiscountPercent(retailPriceValue, retailPriceCampaign);
      availableProducts = Math.min(maxQuantityCampaign - soldQuantity, maxQuantityPerOrderCampaign);
      if (campaignInfo?.campaignType === 'NORMAL') {
        productType = 'MEGA_DAY';
      } else if (campaignInfo?.campaignType === 'FLASH_SALE') {
        productType = 'FLASH_SALE';
        newTags.push(productType);
      }
      // price for campaign
      typeItem = 'CAMPAIGN';
      displayPrice = retailPriceCampaign;
      maxQuantity = maxQuantityCampaign;
    } else {
      productType = 'NORMAL';
      customMessageproduct = `Bạn đã bỏ lỡ giá khuyến mãi ${formatCurrency(retailPriceCampaign)}`;
    }
  } else if (isDeal) {
    productType = 'DEAL';
    typeItem = 'DEAL';
    // MIN
    maxQuantity = Math.min(maxQuantityDeal, maxQuantityPerOrder);
    discountPercentage = findDiscountPercent(retailPriceValue, dealPrice);
    newTags.push(productType);
    availableProducts = deal?.maxQuantity - deal?.quantity || 0;
    percentDealSold = ((deal?.quantity || 0) / deal?.maxQuantity) * 100;
  } else {
    productType = 'NORMAL';
    // availableProducts = limitPerDay;
  }
  // end
  // @deprecase
  // tag giao hang 7 days
  // if (tags?.indexOf(TAG_7DAYS) >= 0) {
  //   newTags.push('TAG_7DAYS');
  // }

  if (isProductCard) {
    return {
      consumedMaxQuantity,
      isNew,
      isAvailable,
      defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
      imageUrls,
      originalImages: getProxyImageList(item?.product?.imageUrls || []),
      maxQuantity,
      maxQuantityPerOrder: maxQuantityPerOrderItem,
      maxQuantityPerDay: isDeal || isCampaign ? 0 : limitPerDay,
      limitPerDay,
      quantityPurchasedToday,
      // price
      salePrice: retailPriceValue,
      salePriceFormated: formatCurrency(retailPriceValue) || '',
      dealPrice,
      dealPriceFormated: formatCurrency(dealPrice) || '',
      displayPrice,
      displayPriceFormated: formatCurrency(displayPrice) || '',
      hidenPrice,
      // sku
      sku: code,
      // combo
      skus,
      skuId: productCode,
      // code
      slug: convertSlug(slug),
      status,
      // deal
      // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
      deal: isCampaign ? null : deal,
      isDeal,
      // seller
      sellerCode,
      seller: withoutLogin
        ? {}
        : {
            code: sellerCode,
            slug, // not yet
          },
      // info
      // DONE: name deal thuannc
      name: (isDeal && deal && deal?.name) || name,
      nameNormal: name,
      productId: productID, // for insider
      unit,
      volume: capitalizeTextFirst(volume),
      statusData,
      productSkuType: type,
      // tags: withoutLogin ? [] : newTags || null,
      tags: (() => {
        if (withoutLogin) return [];
        if (isGetFullTags) return newTags;
        return consumedPriorityTags;
      })(),
      type: typeItem || null,
      productType,
      isGift,
      errorMessageProduct,
      customMessageproduct,
      requiredCertificates,

      // campaign
      isCampaign,
      campaign,
      discountPercent: discountPercentage || 0,
      isHappeningCampaign,
      availableProducts: availableProducts || 0,

      // percentage discount for progress
      percentDealSold: percentDealSold || 0,
      isContractPrice, // giá hợp đồng
      // plus points
      point,
      pointMultiplier,
      messageLimitOrder,
      isActive,
      expiredDate,
    };
  }

  const productInfo = {
    consumedMaxQuantity,
    isNew,
    isAvailable,
    imageUrls,
    imagesProxy: isGift ? imagesProxy || [GIFT_IMAGE] : imagesProxy,
    defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
    originalImages: getProxyImageList(item?.product?.imageUrls || []),
    maxQuantity,
    maxQuantityPerOrder: maxQuantityPerOrderItem,
    maxQuantityPerDay: isDeal || isCampaign ? 0 : limitPerDay,
    limitPerDay,
    quantityPurchasedToday,

    // price
    salePrice: retailPriceValue,
    salePriceFormated: formatCurrency(retailPriceValue) || '',
    dealPrice,
    dealPriceFormated: formatCurrency(dealPrice) || '',
    nextPrice: isDeal || isCampaign ? 0 : nextPriceLevel?.price || 0,
    nextLevel: nextPriceLevel?.code || '',
    displayPrice,
    displayPriceFormated: formatCurrency(displayPrice) || '', // không sài giá âm ví dụ: -410000đ
    hidenPrice,
    // sku
    sku: code,
    // combo
    skus,
    skuId: productCode,
    kind: retailPriceType,
    // code
    slug: convertSlug(slug),
    status,
    ingredients,
    ingredientCodes,
    manufacturerCode,
    categoryCodes,

    // type

    // deal
    // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
    deal: isCampaign ? null : deal,
    isDeal,
    // seller
    sellerCode,
    seller: withoutLogin
      ? {}
      : {
          code: sellerCode,
          slug, // not yet
        },
    // info
    // DONE: name deal thuannc
    name: (isDeal && deal && deal?.name) || name,
    nameNormal: name,
    productId: productID, // for insider
    unit,
    origin,
    description: descriptionInfo,
    volume: capitalizeTextFirst(volume),
    weight,
    productVolume: width * height * length,
    statusData,
    productSkuType: type,
    // tags: withoutLogin ? [] : newTags || null,
    tags: (() => {
      if (withoutLogin) return [];
      if (isGetFullTags) return newTags;
      return consumedPriorityTags;
    })(),
    type: typeItem || null,
    productType,
    isGift,
    errorMessageProduct,
    customMessageproduct,
    requireGPPMessage,
    requiredCertificates,
    documentFiles,

    // campaign
    isCampaign,
    campaign,
    discountPercent: discountPercentage || 0,
    isHappeningCampaign,
    availableProducts: availableProducts || 0,

    // percentage discount for progress
    percentDealSold: percentDealSold || 0,
    isContractPrice, // giá hợp đồng
    // plus points
    point,
    pointMultiplier,
    messageLimitOrder,
    isActive,
    expiredDate,
    sellerSubCategoryCode,
  };

  // ưu tiên tag theo thứ tự -> hang_dat_truoc,...

  return productInfo;
};

const mapDataProduct = async ({
  ctx,
  result,
  isGetQuantity = false,
  withoutLogin = false,
  isAvailable = false,
  isProductCard = false,
  isGetFullTags = false,
}) => {
  try {
    if (!isValid(result)) {
      return result;
    }
    let cartObject = new Map();
    let limitSkus = new Map();

    const { data: dataProducts = [] } = result || {};
    let data = dataProducts;
    if (isAvailable) {
      data = data.filter((item) => item.isAvailable);
    }

    const skuCodes = data.map((item) => item?.sku?.code);

    if (!withoutLogin) {
      const tasks = [];
      if (isGetQuantity) {
        tasks.push(
          CartClientV2.getCartItemList({ ctx }).then((cartRes) => {
            if (isValid(cartRes)) {
              const cartItems = getData(cartRes);
              if (cartItems?.length > 0) {
                cartObject = convertArrayToMap(cartItems, 'sku');
              }
            }
          }),
        );
      }

      // lấy config theo ngày
      tasks.push(
        ProductClientV2.getSkusLimit({ ctx, skuCodes }).then((limitSkusRes) => {
          const skusLimitData = getData(limitSkusRes)?.filter((item) => item.isActive);
          limitSkus = convertArrayToMap(skusLimitData, 'itemCode');
        }),
      );

      await Promise.all(tasks);
    }

    // eslint-disable-next-line no-param-reassign
    result.data =
      data?.map((item) => {
        // 20Apr thêm limitPerDay
        const info = convertProductV2(
          { ...item, limitPerDay: limitSkus?.get(item?.sku?.itemCode)?.quantity || 0, withoutLogin, isProductCard },
          isGetFullTags,
        );
        return {
          ...info,
          quantity: cartObject?.get(info.sku)?.quantity || 0,
        };
      }) || [];

    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/* ============= PUBLIC FUNCTION ============== */
async function getDataProductDetail({ ctx, slug = '' }) {
  const params = {};

  if (slug) {
    params.q = convertSlug(slug);
  } else {
    const { query } = ctx;
    params.q = convertSlug(query?.slug);
  }
  const { params: newParams } = WebServiceUtils.addQueryOption({ params });
  const result = await WebServiceClient.loadProductDetail({ ctx, params: newParams });

  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result });
}

async function getDataProductBySlugs({ ctx, slugs = [], isGetFullTags = false }) {
  const results = await Promise.all(
    slugs.map((slug) => {
      const params = {
        q: convertSlug(slug),
        queryOption: {},
      };
      const { params: newParams } = WebServiceUtils.addQueryOption({ params });
      return WebServiceClient.loadProductDetail({ ctx, params: newParams });
    }),
  );
  const result = {
    status: HTTP_STATUS.Ok,
    data: results?.filter((res) => isValid(res))?.map((productRes) => getFirst(productRes)) || [],
  };

  return mapDataProduct({ ctx, result, isGetQuantity: false, isAvailable: true, isGetFullTags });
}

// search
async function getProductsFuzzySearch({ body, ctx = null }) {
  const { body: bodyReq } = WebServiceUtils.addQueryOption({ body });
  const { text } = bodyReq || {};
  // chặn search những keyword cấm
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;

    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(StringUtils.changeAlias(textSearch)) >= 0
    ) {
      return ResponseUtils.notfound('Keyword is not valid');
    }
  }

  const params = { body: bodyReq };
  if (ctx) {
    Object.assign(params, { ctx });
  }
  const res = await WebServiceClient.loadProductsFuzzySearch(params);

  return res;
}

const loadProductFuzzy = async ({ ctx, offset, limit = PAGE_SIZE_30, filter, text = '', sort = '', isUseRawData = false, ...params }) => {
  const body = {
    text,
    offset,
    limit,
    getTotal: true,
    sort,
    filter,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
    ...params,
  };

  // const result = await getProductsFuzzySearch({ body });

  const queryData = { body };
  if (ctx) {
    Object.assign(queryData, { ctx });
  }
  const result = await getProductsFuzzySearch(queryData);

  if (isUseRawData) return result;

  const mappedData = await mapDataProduct({ ctx, result, ...params });
  return {
    ...mappedData,
  };
};

const loadRecentProducts = async ({ ctx, offset, limit = PAGE_SIZE_30, filter, sort = '', isUseRawData = false, ...params }) => {
  const body = {
    offset,
    limit,
    getTotal: true,
    sort,
    filter,
    ...params,
  };

  const result = await WebServiceClient.loadRecentProducts({ body });

  if (isUseRawData) return result;

  const mappedData = await mapDataProduct({ ctx, result, ...params });
  return {
    ...mappedData,
  };
};

async function searchByKeywords(keyword = '', hasIngredients = true) {
  const searchStrategy = {
    text: true,
    keyword: true,
    ingredient: hasIngredients,
  };
  const body = {
    text: keyword,
    offset: 0,
    limit: PAGE_SIZE_30,
    searchStrategy,
  };

  const result = getProductsFuzzySearch({ body });
  return getData(result);
}

// cart
async function getCart({ ctx }) {
  const params = {};
  params.queryOption = 'price';
  const cartRes = await WebServiceClient.loadCart({ ctx, params });

  if (isValid(cartRes)) {
    return getFirst(cartRes);
  }
  return cartRes;
}

// chuyển data mới thành cấu trúc cũ để ít thay đổi nhất
const convertProductLiteToProductNormal = (data) =>
  data?.map(({ displayPrice, name, slug, sellerInfo }) => ({
    displayPrice,
    name,
    sku: {
      name,
      slug,
      sellerCode: sellerInfo?.sellerCode,
      retailPriceValue: displayPrice,
    },
    product: {
      name,
    },
    slug,
    sellerInfo,
  }));

// search lite dành cho mỗi cái search ở NAVBAR
const searchFuzzyLite = async (body) => {
  const searchRes = await WebServiceClient.searchFuzzyLite(body);
  const data = getData(searchRes);
  return data;
};

export default {
  getDataProductDetail,
  getDataProductBySlugs,
  getProductsFuzzySearch,
  searchByKeywords,
  getCart,
  searchFuzzyLite,
  loadProductFuzzy,
  loadRecentProducts,
};
